.tipsy {
    font-size: 10px;
    position: absolute;
    padding: 5px;
    word-wrap: break-word;
    z-index: 100000;
}

.tipsy-inner {
    background-color: #000;
    color: #FFF;
    max-width: 200px;
    padding: 5px 8px 4px 8px;
    text-align: center;
}

/* Rounded corners */
.tipsy-inner {
    border-radius: 3px;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
}

/* Uncomment for shadow */
/*.tipsy-inner { box-shadow: 0 0 5px #000000; -webkit-box-shadow: 0 0 5px #000000; -moz-box-shadow: 0 0 5px #000000; }*/

.tipsy-arrow {
    position: absolute;
    width: 0;
    height: 0;
    line-height: 0;
    border: 5px dashed #000;
}

/* Rules to colour arrows */
.tipsy-arrow-n {
    border-bottom-color: #000;
}

.tipsy-arrow-s {
    border-top-color: #000;
}

.tipsy-arrow-e {
    border-left-color: #000;
}

.tipsy-arrow-w {
    border-right-color: #000;
}

.tipsy-n .tipsy-arrow {
    top: 0px;
    left: 50%;
    margin-left: -5px;
    border-bottom-style: solid;
    border-top: none;
    border-left-color: transparent;
    border-right-color: transparent;
}

.tipsy-nw .tipsy-arrow {
    top: 0;
    left: 10px;
    border-bottom-style: solid;
    border-top: none;
    border-left-color: transparent;
    border-right-color: transparent;
}

.tipsy-ne .tipsy-arrow {
    top: 0;
    right: 10px;
    border-bottom-style: solid;
    border-top: none;
    border-left-color: transparent;
    border-right-color: transparent;
}

.tipsy-s .tipsy-arrow {
    bottom: 0;
    left: 50%;
    margin-left: -5px;
    border-top-style: solid;
    border-bottom: none;
    border-left-color: transparent;
    border-right-color: transparent;
}

.tipsy-sw .tipsy-arrow {
    bottom: 0;
    left: 10px;
    border-top-style: solid;
    border-bottom: none;
    border-left-color: transparent;
    border-right-color: transparent;
}

.tipsy-se .tipsy-arrow {
    bottom: 0;
    right: 10px;
    border-top-style: solid;
    border-bottom: none;
    border-left-color: transparent;
    border-right-color: transparent;
}

.tipsy-e .tipsy-arrow {
    right: 0;
    top: 50%;
    margin-top: -5px;
    border-left-style: solid;
    border-right: none;
    border-top-color: transparent;
    border-bottom-color: transparent;
}

.tipsy-w .tipsy-arrow {
    left: 0;
    top: 50%;
    margin-top: -5px;
    border-right-style: solid;
    border-left: none;
    border-top-color: transparent;
    border-bottom-color: transparent;
}
